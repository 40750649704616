import styled from '@emotion/styled';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ProfileIcon } from 'shared/components/icons';
import { H2V2, Subheading } from 'shared/components/Text';
import {
  lavenderUpdate,
  lightBlack,
  progressBarGreen,
} from 'shared/styles/colors';
import ProgressBar from './ProgressBar';
import UpdatePointsBar from './UpdatePointsBar';
import { Aviator } from 'shared/components/Text';
import { useMutation } from '@apollo/client';
import { UPDATE_SPRINT_POINTS } from 'screens/SprintCheckIn/components/mutation.sprints';
import { SprintDay } from 'shared/types/typedDocumentNodes';
import { AnimatePresence, motion, useAnimation } from 'framer-motion';
import PartyParrotGif from 'shared/static/images/party-parrot.gif';
import ConfettiExplosion from 'react-confetti-explosion';

interface Props {
  projectName: string;
  sprintPointsCompleted: number;
  sprintGoal: number;
  assignee: string;
  hasUpdate: boolean;
  sprintId: number | null;
  sprintDay: SprintDay | null;
}

const ProjectBox = ({
  projectName,
  sprintGoal,
  sprintPointsCompleted,
  assignee,
  hasUpdate,
  sprintId,
  sprintDay,
}: Props) => {
  const projectBoxRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [pointGoal, setPointGoal] = useState(sprintGoal ?? 0);
  const [pointsCompleted, setPointsCompleted] = useState(sprintPointsCompleted);
  const [isActive, setIsActive] = useState(false);
  const [updateSprintPoints] = useMutation(UPDATE_SPRINT_POINTS, {
    refetchQueries: ['Projects'],
  });

  const [isParrotActive, setIsParrotActive] = useState(false);
  const [isExploding, setIsExploding] = useState(false);

  const boxBorderControls = useAnimation();
  const parrotControls = useAnimation();

  useEffect(() => {
    if (sprintGoal > 0 && sprintPointsCompleted === sprintGoal) {
      setIsParrotActive(true);
      triggerParrotAnimation();
    } else if (isParrotActive) {
      triggerParrotLeavingAnimation();
      setIsParrotActive(false);
    }
  }, [sprintGoal, sprintPointsCompleted, isParrotActive]);

  const triggerParrotAnimation = async () => {
    const randomDirection = Math.random() < 0.5 ? -1 : 1;
    const randomDirection2 = Math.random() < 0.5 ? -1 : 1;
    const startX = randomDirection * 250; // Start from left or right randomly
    const startY = -250;
    const rotations = 10;

    await parrotControls.start({
      x: [startX, 0],
      y: [startY, 0],
      scale: [0.15, 5, 1],
      rotate: [0, rotations * 360 * randomDirection2],
      opacity: [0, 1],
      transition: {
        duration: 1.5,
        ease: 'easeInOut',
        rotate: {
          duration: 1.5,
          ease: 'circOut',
        },
      },
    });
    triggerBorderAnimation();
  };

  const triggerParrotLeavingAnimation = async () => {
    const rotations = 20;

    await parrotControls.start({
      scale: [1, 10],
      rotate: [0, rotations * 360],
      opacity: [1, 0],
      transition: {
        duration: 1.25,
        ease: 'easeIn',
        rotate: {
          duration: 1.5,
          ease: 'circOut',
        },
      },
    });
  };

  const triggerBorderAnimation = async () => {
    setIsExploding(true);
    await boxBorderControls.start({
      boxShadow: [
        `0 0 0 0px ${progressBarGreen}`,
        `0 0 0 8px ${progressBarGreen}`,
        `0 0 0 0px ${progressBarGreen}`,
      ],
      scale: [1, 1.0075, 1],
      transition: {
        duration: 0.75,
        repeat: 3,
      },
    });
  };

  const handleUpdatePoints = ({
    goal,
    completedPoints,
  }: {
    goal: string | number;
    completedPoints: string | number;
  }) => {
    if (!sprintId || !sprintDay?.id) {
      // TODO handle error;
      return;
    }
    updateSprintPoints({
      variables: {
        sprintId: sprintId,
        sprintDayId: sprintDay.id,
        completedPoints: Number(completedPoints),
        totalPoints: Number(goal),
      },
    });
    setPointsCompleted(Number(completedPoints));
  };

  const handleClickOutside = useCallback(({ target }: MouseEvent) => {
    if (
      projectBoxRef.current &&
      !projectBoxRef.current.contains(target as Node)
    ) {
      setIsActive(false);
    }
  }, []);

  useEffect(() => {
    document.removeEventListener('mousedown', handleClickOutside);
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setPointGoal(sprintGoal);
    setPointsCompleted(sprintPointsCompleted);
    if (inputRef.current) {
      inputRef.current.value = String(sprintGoal);
    }
  }, [sprintGoal, sprintPointsCompleted]);

  return (
    <ProjectBoxContainer
      ref={projectBoxRef}
      onClick={() => {
        setIsActive(true);
      }}
      animate={boxBorderControls}
    >
      {hasUpdate && <ProjectUpdateIndicator />}
      <TopTextContainer>
        <TopText>{projectName}</TopText>
        <TopText>
          {pointsCompleted}/
          <GoalInput
            ref={inputRef}
            isActive={isActive}
            contentLength={String(pointGoal).length}
            defaultValue={sprintGoal}
            type="tel"
            onChange={(e) =>
              e.target.value && setPointGoal(parseInt(e.target.value))
            }
            onBlur={(e) =>
              handleUpdatePoints({
                goal: e.target.value,
                completedPoints: pointsCompleted,
              })
            }
          />
        </TopText>
      </TopTextContainer>
      {!isActive && (
        <FlexContainer>
          <ProfileIcon />
          <AssigneeText>: {assignee}</AssigneeText>
        </FlexContainer>
      )}
      {isActive ? (
        <UpdatePointsBar
          goal={pointGoal}
          pointsCompleted={pointsCompleted}
          setPointsCompleted={setPointsCompleted}
          handleUpdatePoints={handleUpdatePoints}
        />
      ) : (
        <ProgressBar goal={pointGoal} pointsCompleted={pointsCompleted} />
      )}
      {isExploding && (
        <ConfettiExplosion onComplete={() => setIsExploding(false)} />
      )}
      <AnimatePresence>
        <FlyingParrot
          initial={{ x: '100%', opacity: 0 }}
          animate={parrotControls}
          exit={{ x: '100%', opacity: 0 }}
        />
      </AnimatePresence>
    </ProjectBoxContainer>
  );
};

export default ProjectBox;

const ProjectBoxContainer = styled(motion.div)({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  width: 590,
  height: 102,
  border: `3px solid ${lightBlack}`,
  marginBottom: 34,
  cursor: 'pointer',
});

const FlyingParrot = styled(motion.div)`
  position: absolute;
  width: 50px;
  height: 50px;
  top: -36px;
  right: 10px;
  background-image: url(${PartyParrotGif});
  background-size: contain;
  background-repeat: no-repeat;
  pointer-events: none;
`;

const TopTextContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  width: 560,
  padding: '4px 13px 0px 0px',
});

const TopText = styled(H2V2)({
  fontSize: 36,
  lineHeight: 1,
});

const AssigneeText = styled(Subheading)({
  fontSize: 20,
  color: lightBlack,
  textAlign: 'left',
  marginLeft: 4,
});

const FlexContainer = styled.div({
  display: 'flex',
  justifyContent: 'flex-start',
  width: 560,
});

const ProjectUpdateIndicator = styled.div({
  position: 'absolute',
  left: 0,
  width: 18,
  height: '100%',
  backgroundColor: lavenderUpdate,
});

const GoalInput = styled.input(
  ({
    isActive,
    contentLength,
  }: {
    isActive: boolean;
    contentLength: number;
  }) => ({
    width: isActive ? 40 : contentLength * 19,
    height: 36,
    fontSize: 36,
    fontFamily: Aviator,
    textAlign: isActive ? 'center' : 'right',
    backgroundColor: 'transparent',
    marginLeft: isActive ? 3 : 0,
    border: isActive ? '1px solid black' : 'none',
    cursor: isActive ? 'text' : 'pointer',
  })
);
